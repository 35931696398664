import React, {Component} from 'react';
import {withData} from './components/react-data-hoc';
import {Route, Switch, BrowserRouter, Redirect} from 'react-router-dom';
import Archiv from './components/Archiv';
import {Page} from './components/Page';
import _np from 'nested-property';
import {apiUrl} from "./constants/api-url";
import {Loader} from "./components/Loader";

class Router extends Component {
    render() {
        const {data} = this.props;
        return (
            <BrowserRouter>
                <Switch>

                    <Route
                        path="/archiv"
                        render={() => <Archiv dataSource={`${apiUrl}/archiv.json`}/>}
                    />

                    <Route render={() =>
                        <Route
                            path="/:view/:slug/:position"
                            children={route => {

                                const params = _np.get(route, "match.params");
                                const segments = {
                                    view: _np.get(params, "view"),
                                    slug: _np.get(params, "slug"),
                                    position: _np.get(params, "position")
                                };

                                const viewExists = (segments.view === "view" || segments.view === "info" || segments.view === "studio");
                                const entry = data.projects.find(e => e.slug === segments.slug);
                                const positionExists = entry && params.position >= 0 && params.position < entry.media.length;

                                if (viewExists && entry && positionExists) {
                                    return <Page {...route} {...this.props} entry={entry} segments={segments}/>;
                                } else {
                                    const nextView = viewExists ? segments.view : "view";
                                    const nextEntry = entry ? segments.slug : data.projects[0].slug;
                                    const nextPosition = positionExists ? segments.position : 0;
                                    return <Redirect to={`/${nextView}/${nextEntry}/${nextPosition}`}/>;
                                }
                            }}
                        />
                    }/>

                </Switch>
            </BrowserRouter>
        );
    }
}

export default withData(Router,Loader);