import React, {Component} from 'react';
import {Transition} from 'react-transition-group';
import {View} from './View';
import {Studio} from './Studio';
import {Info} from './Info';

export class Page extends Component {
    render() {
        const {segments} = this.props;
        const duration = 1000;
        return (
            <div className={`Page state-${segments.view}`}>
                <Transition timeout={duration} in={segments.view === "studio"} unmountOnExit mountOnEnter>
                    <div className="Studio-Wrapper">
                        <Studio {...this.props}/>
                    </div>
                </Transition>
                <Transition timeout={duration} in={segments.view === "info"} unmountOnExit mountOnEnter>
                    <div className="Info-Wrapper">
                        <Info {...this.props}/>
                    </div>
                </Transition>
                <div className="View-Wrapper">
                    <View {...this.props}/>
                </div>
            </div>
        );
    }
}