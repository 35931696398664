import React from 'react';
import Router from './Router.js';
import {apiUrl} from './constants/api-url.js';

export class App extends React.Component {
  render() {
    return (
      <Router dataSource={`${apiUrl}/all.json`} />
    );
  }
}

export default App;