import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {IconButton} from "./IconButton";
import Measure from 'react-measure';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {ViewController} from './ViewController';

export class View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbnails: false,
        };
        this.toggleThumbnails = () => this.setState({thumbnails: !this.state.thumbnails});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location !== this.props.location) {
            this.setState({thumbnails: false})
        }
    }

    render() {
        const {segments} = this.props;
        const nextView = segments.view !== "studio" ? "studio" : "view";
        return (
            <Fragment>

                <div className={`Section-Header`}>
                    <Link to={`/${nextView}/${segments.slug}/${segments.position}`} className={`text-large`}>
                        JS <span className={`page-title d-none d-sm-inline `}>Architekturfotografie</span>
                    </Link>
                    <span className={`float-right`}>
						<IconButton type={"span"} onClick={this.toggleThumbnails}
                                    icon={this.state.thumbnails ? "close" : "apps"}/>
						<IconButton type={"link"} to={`/${nextView}/${segments.slug}/${segments.position}`}
                                    icon={"notes"}/>
					</span>
                </div>

                <div className={`View-Content`}>
                    <TitleContainer {...this.props} />
                    <ImageContainer {...this.props} {...this.state} toggleThumbnails={this.toggleThumbnails}/>
                </div>

            </Fragment>
        )
    }
}

const TitleContainer = ({data, segments, entry}) => {
    return (
        <div className={`Title-Container`}>
            <div className={`Info-Button`} style={{
                height: 100 / data.projects.length + '%',
                top: entry.index * 100 / data.projects.length + '%'
            }}>
                <IconButton type={"link"}
                            to={`/${segments.view === "info" ? "view" : "info"}/${segments.slug}/${segments.position}`}
                            icon={"more_horiz"}/>
            </div>
            {data.projects.map(project => {
                const style = {height: `${100 / data.projects.length}%`};
                const active = (segments.slug === project.slug ? "active" : "");
                return (
                    <Link key={project.id}
                          to={`/${(active && segments.view !== "view") ? "info" : segments.view}/${project.slug}/0`}
                          style={style}
                          className={`Title-Item ${active}`}>
                        <span>{project.title}</span>
                    </Link>
                )
            })}
        </div>
    )
};

const ImageContainer = props => {
    const {entry} = props;
    const {segments} = props;
    const imageData = entry.media[segments.position];
    return (
        <div className={`ImageView`}>
            <Measure>
                {({measureRef, contentRect}) => {
                    const containerRatio = contentRect.entry.height / contentRect.entry.width;
                    const imageRatio = imageData.height / imageData.width;
                    const fitHeight = containerRatio < imageRatio;
                    const imageStyle = {
                        width: `${fitHeight ? contentRect.entry.height / imageRatio + 'px' : '100%'}`,
                        height: `${fitHeight ? '100%' : contentRect.entry.width * imageRatio + 'px'}`
                    }
                    return (
                        <div className={`Image-Container`} ref={measureRef}>
                            <TransitionGroup component={null}>
                                <CSSTransition key={segments.slug + segments.position} timeout={600}
                                               classNames={"fade"}>
                                    <div className={`Image`} style={imageStyle}>
                                        <Image media={imageData}/>
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    )
                }}
            </Measure>
            <CSSTransition in={props.thumbnails} timeout={600} classNames={'fade'} unmountOnExit mountOnEnter>
                <Thumbnails {...props} />
            </CSSTransition>
            <ViewController {...props} />
        </div>
    )
};

class Image extends Component {
    state = {loaded: false};

    render() {
        const {urlDesktop, title} = this.props.media;
        const {loaded} = this.state;
        const style = {
            transition: 'opacity 600ms linear',
            opacity: loaded ? 1 : 0,
        }
        return (
            <img
                src={urlDesktop}
                alt={title}
                style={style}
                onLoad={() => this.setState({loaded: true})}
            />
        );
    }
}

const Thumbnails = ({data, entry}) => {
    return (<div className={`Thumbnails`}>
        {data.projects.map(project =>
            <div key={project.id} className={'Thumbnails-Row'} style={{height: 100 / data.projects.length + '%'}}>
                {project.media.map((media, index) =>
                    <Link
                        key={media.uroDesktop + '' + index}
                        to={`/view/${project.slug}/${index}`}
                        className={"Thumbnail"}
                        style={{width: 100 / project.media.length + '%'}}
                    >
                        <img src={media.urlThumbnail} alt={media.title}/>
                    </Link>
                )}
            </div>
        )}
    </div>)
}




export default View;