import React, { Component,Fragment } from 'react';
import {Link} from "react-router-dom";

export class Info extends Component {
	render() {
		const {segments} = this.props;
		const entry = this.props.data.projects.find(e=>e.slug === segments.slug);
		return (
			<Fragment>
				<div className={`Section-Header`}>
					<Link to={`/view/${segments.slug}/${segments.position}`} className={`IconButton btn btn-link mr-0`}>
						<div className="IconButton-Inner"><i className="material-icons">close</i></div>
					</Link>
				</div>
				<div className={`Info-Content`}>
					<h1 className={`text-large`}>{entry.title}</h1>
					<div dangerouslySetInnerHTML={{ __html: entry.projectInfo.description }} />
				</div>
			</Fragment>
		)
	}
}
