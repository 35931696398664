import React from 'react';
import {Link} from "react-router-dom";

export const IconButton = props => {
    if( props.type === "link" )
        return(
            <Link to={props.to} className={`IconButton btn btn-link`}>
                <i className="material-icons">{props.icon}</i>
            </Link>
        );
    else if( props.type === "span" )
        return (
            <span onClick={props.onClick} className={`IconButton btn btn-link`}>
                <i className="material-icons">{props.icon}</i>
            </span>
        );
};
