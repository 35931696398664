import React from "react";
import {useSwipeable} from "react-swipeable";
import {useHistory} from 'react-router-dom';

export const ViewController = ({data, segments, entry}) => {
    let history = useHistory();
    function increase( _axis = 'X', _increase = true ) {
        let nextPosition = parseInt(segments.position);
        let nextSlug = entry.slug;
        const projectIndex = entry.index;
        const increaseProject = ()=>{
            if( projectIndex + 1 >= data.projects.length ) {
                return 0;
            }else{
                return projectIndex + 1;
            }
        };
        const decreaseProject = () => {
            if( projectIndex-1 < 0 ) {
                return data.projects.length-1;
            }else{
                return projectIndex-1
            }
        };
        if( _axis === 'X' ){
            if( _increase ){
                nextPosition += 1;
                if( nextPosition >= entry.media.length ){
                    nextPosition = 0;
                    nextSlug = data.projects[increaseProject()].slug;
                }
            }else if( !_increase ){
                nextPosition -= 1;
                if( nextPosition < 0 ){
                    nextPosition = data.projects[decreaseProject()].media.length-1;
                    nextSlug = data.projects[decreaseProject()].slug;
                }
            }
        }else if( _axis === 'Y' ){
            if( _increase ){
                nextSlug = data.projects[increaseProject()].slug;
                nextPosition = 0;
            }else if( !_increase ){
                nextSlug =  data.projects[decreaseProject()].slug;
                nextPosition =  0;
            }
        }
        history.push(`/${segments.view}/${nextSlug}/${nextPosition}`);
    }
    const swipeHandlers = useSwipeable({
        onSwiped: (eventData) => { _handleSwiped(eventData.dir) },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
    const _handleSwiped = (dir) => {
        switch(dir){
            case "Left":
                increase('X',true);
                break;
            case "Up":
                increase('Y',true);
                break;
            case "Down":
                increase('Y',false);
                break;
            case "Right":
                increase('X',false);
                break;
            default:
                break;
        }
    }
    const style={
        horizontal:{
            width: `${100/entry.media.length}%`,
        },
        vertical:{
            height: `${100/data.projects.length}%`,
        },
        mark:{
            left: `${parseInt(segments.position)*(100/entry.media.length)}%`,
            top: `${entry.index*(100/data.projects.length)}%`,
        }
    }
    return(
        <div className={`ViewController`} {...swipeHandlers}>
            <div className={`Position-Mark`} style={{ ...style.horizontal, ...style.vertical, ...style.mark }} />
            <div className={'Horizontal-Rule'}>
                {entry.media.map((m,i)=><div key={i} className={`Horizontal-Mark`} style={style.horizontal} />)}
            </div>
            <div className={'Horizontal-Rule bottom'}>
                {entry.media.map((m,i)=><div key={i} className={`Horizontal-Mark`} style={style.horizontal} />)}
            </div>
            <div className={'Vertical-Rule'}>
                {data.projects.map((m,i)=><div key={i} className={`Vertical-Mark`} style={style.vertical} />)}
            </div>
            <div className={`Prev-Area`} onClick={()=>increase('X',false)} />
            <div className={`Next-Area`} onClick={()=>increase('X',true)} />
            <KeyController increase={increase} />
        </div>
    );
};

class KeyController extends React.Component {
    componentDidMount(){
        window.addEventListener( "keyup" , this.handleKeyDown.bind(this) );
    }
    handleKeyDown(event){
        event.preventDefault();
        switch(event.keyCode){
            case 37:
                event.preventDefault();
                this.props.increase('X',false);
                break;
            case 38:
                event.preventDefault();
                this.props.increase('Y',false);
                break;
            case 39:
                event.preventDefault();
                this.props.increase('X',true);
                break;
            case 40:
                event.preventDefault();
                this.props.increase('Y',true);
                break;
            default:
                break;
        }
    }
    render() { return null; }
}