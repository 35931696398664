import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withData} from "./react-data-hoc";
import {IconButton} from "./IconButton";
import {Loader} from "./Loader";


class Archiv extends Component {
    render() {
        return (
            <div className="Archiv">
                <ArchivHeader/>
                {this.props.data.data.map((project) =>
                    <div key={project.id} className="Archiv-Entry">
                        <div className={'container-fluid px-0'}>
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-large">{project.title}</h1>
                                </div>
                                <div className="col-12 col-md-6"
                                     dangerouslySetInnerHTML={{__html: project.projectInfo.description}}
                                />
                            </div>
                            <div className="Archiv-ImageContainer">
                                {
                                    project.media.map(image =>
                                        <img key={image.urlMobile}
                                             src={image.urlMobile}
                                             alt={image.title}
                                             className={`Archiv-Image ${ image.width > image.height ? "querformat" : "hochformat"}`}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withData(Archiv,Loader);


const ArchivHeader = props => (
    <div className={`Section-Header`}>
        <Link to={`/studio`} className={`text-large`}>
            JS <span className={`page-title`}>Architekturfotografie</span>
        </Link>
        <span className={`float-right`}>
            <IconButton type={"link"} to={`/`} icon={"close"}/>
        </span>
    </div>
);
