import React, { Component, Fragment } from 'react';
import {Link} from "react-router-dom";
import {IconButton} from "./IconButton";

export class Studio extends Component {
	render() {
		const {segments} = this.props;
		const {info} = this.props.data;
		return (
			<Fragment>
				<div className={`Section-Header text-right`}>
					<IconButton type={"link"} to={`/view/${segments.slug}/${segments.position}`} icon={"close"} />
				</div>
				<div className="Studio-Content container-fluid px-0">
					<div className="row">
						<div className="col-12">
							JS Architekturfotografie
						</div>
						{ info.columns.map( (column,index) => <TextColumn key={index} {...column} /> ) }
						<div className="col-12 TextColumn">
							<Link to="/archiv" className="Archiv-Btn btn btn-sm rounded-pill btn-outline-dark">Archiv</Link>
						</div>
					</div>
					<div className="Credits">
						<small><a href="http://julianhumm.com">Website gestaltet und entwickelt von Julian Humm.</a></small>
					</div>
				</div>
			</Fragment>
		);
	}
}

const TextColumn = ({colWidth,body}) => {
	return (
		<div className={`TextColumn ${ colWidth === "ganz" ? "col-12 col-md-9" : "col-12 col-sm-6" } `} >
			<div dangerouslySetInnerHTML={{ __html: body }} />
		</div>
	)
};
