import React, {Component, Fragment} from 'react';
import axios from 'axios';
import {CSSTransition} from 'react-transition-group';
import "./index.css";

export function withData(
    Main,
    Loader = LoaderComponent,
    Crash = CrashComponent
) {
    return class WithData extends Component {
        constructor(props) {
            super(props);
            this.state = {
                data: null,
                error: null,
                pending: true,
                failed: false,
                success: false,
            }
        }

        componentDidMount() {
            const {dataSource} = this.props
            axios.get(dataSource)
                .then(response => {
                    this.setState({
                        data: response.data,
                        pending: false,
                        success: true
                    });
                })
                .catch(error => {
                    this.setState({
                        error: error,
                        pending: false,
                        failed: true
                    });
                });
        }

        render() {
            return (
                <Fragment>
                    <CSSTransition
                        in={this.state.pending}
                        timeout={300}
                        classNames="fade"
                        mountOnEnter
                        unmountOnExit
                    >
                        <Loader/>
                    </CSSTransition>
                    <CSSTransition
                        in={this.state.failed}
                        timeout={300}
                        classNames="fade"
                        mountOnEnter
                        unmountOnExit
                    >
                        <Crash/>
                    </CSSTransition>
                    <CSSTransition
                        in={this.state.success}
                        timeout={300}
                        classNames="fade"
                        mountOnEnter
                        unmountOnExit
                    >
                        <Main {...this.state} {...this.props} />
                    </CSSTransition>
                </Fragment>
            );
        }
    }
}

const LoaderComponent = props => (
    <div className="LoaderComponent">
        loading …
    </div>
);

const CrashComponent = props => (
    <div className="CrashComponent">
        Ups, something went wrong :-/ <br/> Please try to refresh this website.
    </div>
);